body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom css */
.featuredescription > p {
  font-size: 1.3rem !important;
}

.coming-soon{
  height: 100vh;
  width: 100vw;
  background: url("./assets/image/comingsoon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 768px) {
 .coming-soon{
  background: url("./assets/image/mobile_comingsoon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
 }
}